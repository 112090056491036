<template>
  <div class="sous-category-setting" v-if="selectedCategorie != null">
    <div>
      <v-row>
        <v-col cols="6">
          <div class="style-title-setting-global">
            <span
              class="bold-700 mr-2 title-hover cursor-pointer"
              @click="handelCancel($event)"
            >
              {{ $i18n.locale == 'fr' ? 'Catégorie' : 'Category' }}
              <v-icon class="color-crm">mdi-chevron-right</v-icon></span
            >
            <span
              class="color-crm font-sz-18 bold-500 font-text text-capitalize"
              >{{
                selectedCategorie && selectedCategorie.name
                  ? selectedCategorie.name
                  : selectedCategorie
              }}</span
            >
          </div>
          <div class="message mt-2">
            <div v-if="getCategorieError" class="error-msg">
              <ul v-if="Array.isArray(getCategorieError)">
                <li v-for="(e, index) in getCategorieError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCategorieError }}</span>
            </div>
          </div>
        </v-col>
        <v-col>
          <v-progress-circular
            v-if="getCategorieLoading"
            class="float-right mt-1 mr-2"
            indeterminate
            color="#5C2DD3"
          ></v-progress-circular
        ></v-col>
        <v-col>
          <v-btn
            class="btn-filter float-right"
            dark
            color="#5C2DD3"
            @click.stop="handleClickBtnAddSousCategory"
          >
            <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
            {{
              $i18n.locale == 'fr'
                ? 'Ajouter une sous catégorie'
                : 'Add sub-category'
            }}
          </v-btn></v-col
        >
      </v-row>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="content mt-2" v-else>
      <div
        v-if="
          selectedCategorie &&
            (!selectedCategorie.subCategorie ||
              !selectedCategorie.subCategorie.length)
        "
        class="div-aucun-list"
      >
        <div class="titre">
          {{
            $i18n.locale == 'fr' ? 'Aucun sous catégorie' : 'No sub-category'
          }}
        </div>
      </div>
      <div class="card-scene" ref="cardSceneCategory" v-else>
        <Container
          :drop-placeholder="dropPlaceholderOptions"
          :lock-axis="'y'"
          @drop="onColumnDrop"
          drag-handle-selector=".column-drag-container "
        >
          <Draggable
            v-for="sousCategorie in selectedCategorie.subCategorie"
            :key="sousCategorie.id"
          >
            <div class="mt-1">
              <div class="card-column-header">
                <div class="input-title-sous-type">
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        clearable
                        :label="$t('designation')"
                        dense
                        :placeholder="
                          $i18n.locale === 'fr'
                            ? 'Nom de sous catégorie'
                            : 'Sub category name'
                        "
                        @change="updateNameSousCategorie($event, sousCategorie)"
                        :title="
                          $i18n.locale === 'fr'
                            ? 'Modifier nom de sous catégorie'
                            : 'Modify sub-category name'
                        "
                        :value="sousCategorie.name"
                        :persistent-placeholder="true"
                        outlined
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                      >
                        <template #prepend>
                          <v-icon
                            class="cursor-move mr-2 ml-2 bold-700 color-crm font-sz-30 mb-1"
                            :class="{
                              'column-drag-container':
                                selectedCategorie &&
                                selectedCategorie.subCategorie &&
                                selectedCategorie.subCategorie.length > 1
                            }"
                            >mdi-drag-horizontal-variant</v-icon
                          >
                        </template>
                      </v-text-field></v-col
                    >
                    <v-col cols="3">
                      <v-autocomplete
                        :clearable="true"
                        color="#5C2DD3"
                        :placeholder="$t('searchMsg', { msg: 'Type' })"
                        item-color="#5C2DD3"
                        @change="handleTypeChange($event, sousCategorie)"
                        :loading="getProjectsTypesLoading"
                        v-model="sousCategorie.selectedType"
                        :items="getProjectsTypes"
                        :persistent-placeholder="true"
                        label="Type"
                        item-text="name"
                        dense
                        item-value="id"
                        :no-data-text="
                          $t('noDataOptionMsg', { option: 'type' })
                        "
                        outlined
                        :menu-props="{
                          bottom: true,
                          offsetY: true
                        }"
                      >
                      </v-autocomplete
                    ></v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        :clearable="true"
                        color="#5C2DD3"
                        :placeholder="$t('searchMsg', { msg: $t('sousType') })"
                        item-color="#5C2DD3"
                        @input="handleSousTypeChange(sousCategorie)"
                        v-model="sousCategorie.subtype.data"
                        :items="[...sousCategorie.listSubType]"
                        :persistent-placeholder="true"
                        :label="
                          $i18n.locale === 'fr'
                            ? 'Définit les Sous types'
                            : 'Define Sub-types'
                        "
                        item-text="name"
                        dense
                        item-value="id"
                        :no-data-text="
                          $t('noDataOptionMsg', { option: $t('sousType') })
                        "
                        outlined
                        :menu-props="{
                          bottom: true,
                          offsetY: true
                        }"
                      >
                        <template #append-outer>
                          <v-icon
                            class="font-sz-30 color-crm"
                            :title="
                              $i18n.locale == 'fr'
                                ? 'Configuration des champs'
                                : 'Field configuration'
                            "
                            @click.prevent.stop="
                              sendConfigChamps(sousCategorie)
                            "
                            >mdi-cog-outline</v-icon
                          >
                        </template>
                      </v-autocomplete></v-col
                    >
                    <v-col cols="1">
                      <v-btn
                        class="font-sz-30 bold-700 float-right color-crm"
                        @click="tryDeleteSousCategorie(sousCategorie)"
                        :title="
                          $i18n.locale == 'fr'
                            ? 'Supprimer une sous-catégorie'
                            : 'Delete sub-category'
                        "
                        fab
                        small
                        icon
                      >
                        <v-icon class="font-sz-30 mt-1"
                          >mdi-close-circle-outline</v-icon
                        >
                      </v-btn></v-col
                    >
                  </v-row>
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
      </div>
    </div>

    <!-- Dellete SubCategorie -->
    <v-dialog
      v-model="deleteSousCategorieModal"
      max-width="700"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale == 'fr'
                ? 'Supprimer une sous-catégorie'
                : 'Delete sub-category'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('deleteSousCategorieModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="mb-3">
            {{
              $t('msgdeleted', {
                msg:
                  $i18n.locale === 'fr'
                    ? 'la sous catégorie :'
                    : 'the sub-category :'
              })
            }}
            <strong>
              {{ sousCategorieToDelete ? sousCategorieToDelete.name : '' }}
            </strong>
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getCategorieLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getCategorieError" class="error-msg">
              <ul v-if="Array.isArray(getCategorieError)">
                <li v-for="(e, index) in getCategorieError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCategorieError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="deleteSousCategorie"
            :loading="getCategorieLoading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('deleteSousCategorieModal')">
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Sous_Categorie Modal -->
    <v-dialog
      v-model="addSubCategorieModal"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale == 'fr'
                ? 'Ajouter une sous categorie'
                : 'Add sub-category'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addSubCategorieModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addSubCategorieModal" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  v-model="SousCategorieToAdd.name"
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  :placeholder="$t('searchMsg', { msg: 'Type' })"
                  item-color="#5C2DD3"
                  @change="handleTypeChange($event)"
                  v-model="SousCategorieToAdd.type"
                  :items="getProjectsTypes"
                  :persistent-placeholder="true"
                  label="Type"
                  item-text="name"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOptionMsg', { option: 'type' })"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  :placeholder="
                    $t('searchMsg', {
                      msg: $i18n.locale === 'fr' ? 'sous type' : 'sub type'
                    })
                  "
                  item-color="#5C2DD3"
                  return-object
                  v-model="SousCategorieToAdd.subtype_id"
                  :items="SousCategorieToAdd.type ? getListSubType : []"
                  :loading="getCategorieLoading"
                  :persistent-placeholder="true"
                  :label="$i18n.locale === 'fr' ? 'Sous Type' : 'Sub Type'"
                  item-text="name"
                  dense
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option: $i18n.locale === 'fr' ? 'sous type' : 'sub type'
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getCategorieLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getCategorieError" class="error-msg">
              <ul v-if="Array.isArray(getCategorieError)">
                <li v-for="(e, index) in getCategorieError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCategorieError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="addBlockSousCategorie"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addSubCategorieModal')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      initLoading: true,
      selectedCategorie: null,
      SousCategorieToAdd: {
        name: null,
        subtype_id: [],
        type: null
      },
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      sousCategorieToDelete: null,
      addSubCategorieModal: false,
      deleteSousCategorieModal: false,
      loading: false
    }
  },

  methods: {
    ...mapActions([
      'fetchProjetsTypes',
      'fetchFrojectSubType',
      'addSubCategorie',
      'deleteSubCategorie',
      'updateSubCategorie',
      'resetErrorCategorie',
      'fetchCategoriesSubCategorie',
      'fetchOneCategorie'
    ]),
    sendConfigChamps(sousCategorie) {
      if (this.$route && this.$route.path) {
        this.$router.push(
          this.$route.path + '/subCategorie/' + sousCategorie.id
        )
      }
    },
    handleClickBtnAddSousCategory() {
      this.addSubCategorieModal = true
    },
    handleTypeChange($event, sousCategorie) {
      if ($event) {
        this.fetchFrojectSubType({ id: $event, sousCategorie })
      }
    },
    //add subCategorie
    async addBlockSousCategorie() {
      if (this.$refs.addSubCategorieModal.validate()) {
        this.loading = true
        let count = this.selectedCategorie.subCategorie.length

        const sousCategorie = {
          name: this.SousCategorieToAdd.name,
          order: count
        }
        if (
          this.SousCategorieToAdd &&
          this.SousCategorieToAdd.subtype_id &&
          this.SousCategorieToAdd.subtype_id.id
        ) {
          sousCategorie.subtype_id = this.SousCategorieToAdd.subtype_id.id
        }

        const responce = await this.addSubCategorie({
          categorie: this.selectedCategorie,
          sousCategorie: sousCategorie
        })
        if (responce) {
          // scroll when add new stage
          this.$nextTick(() => {
            this.$refs.cardSceneCategory.scrollTop = this.$refs.cardSceneCategory.scrollHeight
            this.closeDialog('addSubCategorieModal')
            this.fetchListSousType()
          })
        }
        this.loading = false
      }
    },
    //updatednname SubCategorie
    updateNameSousCategorie(e, sousCategorie) {
      this.updateSubCategorie({
        categorie: this.selectedCategorie,
        sousCategorie: sousCategorie,
        data: { name: e }
      })
    },
    //show modal delete SubCategorie
    tryDeleteSousCategorie(sousCategorie) {
      this.sousCategorieToDelete = sousCategorie
      this.deleteSousCategorieModal = true
    },
    //delete subCategorie
    async deleteSousCategorie() {
      const response = await this.deleteSubCategorie({
        categorie: this.selectedCategorie,
        sousCategorie: this.sousCategorieToDelete
      })
      if (response) {
        this.closeDialog('deleteSousCategorieModal')
      }
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'addSubCategorieModal') {
        this.$refs.addSubCategorieModal.resetValidation()
      }
      this.resetModal()
    },

    resetModal() {
      this.sousCategorieToDelete = null
      this.SousCategorieToAdd = {
        name: null,
        subtype_id: [],
        type: null
      }
      this.resetErrorCategorie()
    },

    onColumnDrop(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      this.selectedCategorie.subCategorie = this.applyDrag(
        this.selectedCategorie.subCategorie,
        dropResult
      )
      this.updateSubCategorie({
        categorie: this.selectedCategorie,
        sousCategorie: this.selectedCategorie.subCategorie[addedIndex],
        data: {
          order: addedIndex
        }
      })
    },
    //retour
    handelCancel() {
      if (
        this.$route &&
        this.$route.name &&
        this.$route.name == 'categorie-update-projet'
      ) {
        this.$router.push('/setting/categorie/projets')
      } else {
        this.$router.push('/setting/categorie/contacts')
      }
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    //update SubType
    handleSousTypeChange(sousCategorie) {
      if (sousCategorie) {
        this.updateSubCategorie({
          categorie: this.selectedCategorie,
          sousCategorie: sousCategorie,
          data: {
            subtype_id:
              sousCategorie &&
              sousCategorie.subtype &&
              sousCategorie.subtype.data &&
              sousCategorie.subtype.data.id
                ? sousCategorie.subtype.data.id
                : sousCategorie.subtype.data
          }
        })
      }
    },
    //fetch SubType for SubCategorie
    async fetchListSousType() {
      for (let i = 0; i < this.selectedCategorie.subCategorie.length; i++) {
        if (
          this.selectedCategorie.subCategorie[i].subtype &&
          this.selectedCategorie.subCategorie[i].subtype.data &&
          this.selectedCategorie.subCategorie[i].subtype.data.type &&
          this.selectedCategorie.subCategorie[i].subtype.data.type.data &&
          this.selectedCategorie.subCategorie[i].subtype.data.type.data.id
        ) {
          await this.fetchFrojectSubType({
            id: this.selectedCategorie.subCategorie[i].subtype.data.type.data
              .id,
            sousCategorie: this.selectedCategorie.subCategorie[i],
            categorie: this.selectedCategorie
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCategoriesCategorie',
      'getCategorieLoading',
      'getCategorieError',
      'getProjectsTypes',
      'getListSubType',
      'getProjectsTypesLoading'
    ])
  },
  async mounted() {
    this.initLoading = true
    await this.fetchOneCategorie(this.$route.params.id)
    if (!this.getCategoriesCategorie) {
      this.$router.push('/setting/categorie')
    }

    this.selectedCategorie = this.getCategoriesCategorie
    this.fetchCategoriesSubCategorie({
      selectedCategorie: this.selectedCategorie
    })
    await this.fetchProjetsTypes({
      exclude: ['fields']
    })
    setTimeout(() => {
      this.fetchListSousType()
    })
    this.initLoading = false
  }
}
</script>

<style lang="scss" scoped>
.sous-category-setting {
  .block-menu-users {
    .list-tabs {
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
    }
    .v-tab {
      &:hover {
        text-decoration: none;
      }
    }
    v-tab .v-tab--active {
      &:hover {
        text-decoration: none;
      }
    }
    .menu-item {
      &.router-link-exact-active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .v-tab {
      text-decoration: none;
      font-weight: 600;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.4px;
      font-family: 'Montserrat', sans-serif;
      &.v-tab--active {
        text-decoration: none;
        font-weight: 700;
        font-size: 12px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
      &.v-tab--disabled {
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
  .list-fildes-type-projet {
    max-height: calc(100vh - 400px) !important;
    height: calc(100vh - 400px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-fildes-type-projet::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .list-fildes-type-projet::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-fildes-type-projet::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .form-actions {
    float: right;
    margin-top: 10px;
  }
  // STYLE BLOCK DRAG AND DROP
  .column-drag-handle,
  .ss-column-drag-handle {
    position: absolute;
    top: 9px;
    left: 6px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ss-column-drag-handle {
    top: 5px;
    left: 3px;
  }

  .input-title-sous-type {
    display: flex;
    justify-content: space-between;
    // margin-left: 25px;
    width: 100%;
    .body-drag {
      display: -webkit-box;
      justify-content: space-between;
      input {
        color: black;
        overflow: visible;
        background-color: transparent;
        border: 0px;
        font-size: 15px;
        text-overflow: ellipsis;
      }
      .custom-select-sm {
        width: 139px;
      }
    }
  }
  .icon-sous-categorie {
    .btn-left {
      color: #495057ab;
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      margin-right: 3px;
    }
    .btn-secondary:focus,
    .btn-secondary.focus {
      color: #495057ab;
      background-color: rgba(255, 255, 255, 0.075);
      border-color: rgba(255, 255, 255, 0.075);
    }
  }
  .card-column-header {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 9px;
  }
  .smooth-dnd-container.horizontal {
    display: flex;
  }
  .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    position: relative;
    overflow: visible;
    margin-top: 13px;
    border: 2px solid #5c2dd3;
    padding: 4px;
    background-color: rgba(214, 204, 242, 0.34);
    width: 100%;
    border-radius: 4px;
    border-left: 14px solid #5c2dd3;
  }
  .smooth-dnd-container {
    margin: 3px;
  }
  .smooth-dnd-container {
    margin: 0px 6px 0px 0px;
    position: relative;
    min-height: 30px;
    min-width: 255px;
  }
  .smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    border: 1px solid #6c757d4d;
    margin-right: 8px;
    background-color: #ebebeb5c;
    border-radius: 4px;
  }
  .custom-select-sm {
    height: calc(1.5em + 0.5rem + 7px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 12px;
    width: 77px;
  }
}
</style>
<style lang="scss">
.sous-category-setting {
  .input-title-sous-type {
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none !important;
    }
  }
  .card-scene {
    max-height: calc(100vh - 231px) !important;
    height: calc(100vh - 231px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .card-scene::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .card-scene::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .card-scene::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .scroll {
    max-height: calc(100vh - 300px) !important;
    height: calc(100vh - 300px) !important;
  }
}
</style>
