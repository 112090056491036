import { render, staticRenderFns } from "./SousCategorie.vue?vue&type=template&id=56eefe7f&scoped=true"
import script from "./SousCategorie.vue?vue&type=script&lang=js"
export * from "./SousCategorie.vue?vue&type=script&lang=js"
import style0 from "./SousCategorie.vue?vue&type=style&index=0&id=56eefe7f&prod&lang=scss&scoped=true"
import style1 from "./SousCategorie.vue?vue&type=style&index=1&id=56eefe7f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56eefe7f",
  null
  
)

export default component.exports